@use '../../styleguide/vars.scss';
@use '../../styleguide/colors.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 120px;

  @media screen and (min-width: vars.$medium) {
    margin-top: 150px;
  }

  h3,
  p {
    margin-bottom: 30px;
  }

  p {
    text-align: center;
  }

  svg {
    margin-bottom: 30px;

    @media screen and (min-width: vars.$medium) {
      width: 75%;
    }

    @media screen and (min-width: vars.$large) {
      width: 60%;
    }

    @media screen and (min-width: vars.$extra-large) {
      width: 50%;
    }
  }
}

.button {
  width: 100%;
}

.goHomeLink {
  color: colors.$white;
  text-decoration: none;
  margin-bottom: 30px;
}
